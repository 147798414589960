<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewLocationSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-new-location-sidebar-active', val)"
      @hidden="hide = false"
  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
         Edit Organisation Details
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <b-form

            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

         <!-- Organisation name -->

          <b-col cols="12" md="12" >
                <b-form-group
                    label="Organisation name"
                    label-for="h-name"
                 >
                  <validation-provider
                      #default="{ errors }"
                      name="Organisation Name"
                      rules="required"
                      vid="h-name"
                  >
                  <b-form-input class="mb-1"
                                id="h-name"
                                placeholder="Organisation name"
                                v-model="form.name"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                 </b-form-group>
          </b-col>


               <!-- Organisation type -->

          <b-col cols="12" md="12" >
                <b-form-group
                    label="Organisation type"
                    label-for="h-type"
                 >
                  <v-select
                      label="name"
                      placeholder="Select Organisation type"
                      v-model="practice_type_id"
                      :options="practiceList"
                  />
                 </b-form-group>
          </b-col>


                <!-- Address -->

             <b-col cols="12" md="12" >
                <b-form-group
                    label="Address 1"
                    label-for="h-address-name"
                 >
                  <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                      vid="address"
                  >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MapPinIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                        id="icons-address"
                        placeholder="Address"
                        v-model="form.address"
                    />
                  </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                 </b-form-group>
             </b-col>

          <b-col cols="12" md="12" >
            <b-form-group
                label="Address 2"
                label-for="h-address-name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Address"
                  rules="required"
                  vid="address"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MapPinIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                      id="icons-address"
                      placeholder="Address"
                      v-model="form.address_2"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


               <!-- City & Postal Code -->

             <b-col cols="12" md="12" >
                <b-row>
               <b-col cols="12" md="6" >
                <b-form-group
                    label="City"
                    label-for="h-city-name"
                    class="mr-1"
                 >
                  <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                      vid="City"
                  >
                    <b-form-input
                        id="city"
                        placeholder="Address"
                        v-model="form.city"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                 </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                    label="Postal Code"
                    label-for="h-postalcode-name"
                 >
                  <validation-provider
                      #default="{ errors }"
                      name="Postal Code"
                      rules="required"
                      vid="Postal Code"
                  >
                  <b-form-input class="mb-1"
                                id="h-postalcode-name"
                                placeholder="Postal Code"
                                v-model="form.postal_code"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                 </b-form-group>
               </b-col>
                </b-row>
              </b-col>


                    <!-- Telephone -->

               <b-col cols="12" md="12" >
                   <b-form-group
                      label="Telephone"
                      label-for="h-telephone"
                   >
                     <validation-provider
                         #default="{ errors }"
                         name="Telephone"
                         rules="required|tel|min_tel|max_tel"
                         vid="Telephone"

                     >
                     <b-input-group class="input-group-merge">
                       <b-input-group-prepend is-text>
                         <feather-icon icon="PhoneIcon" />
                       </b-input-group-prepend>
                       <b-form-input
                           v-model="form.phone_number"
                           id="icons-phone"
                           placeholder="0123456789"
                       />
                     </b-input-group>
                       <small class="text-danger">{{ errors[0] }}</small>
                     </validation-provider>

                   </b-form-group>
                </b-col>
                    <!-- Email -->

               <b-col cols="12" md="12" >
                   <b-form-group
                      label="Email"
                      label-for="h-email"
                      
                   >
                   <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="email"
                        vid="Email"

                     >
                     <b-input-group class="input-group-merge">
                       <b-input-group-prepend is-text>
                         <feather-icon icon="MailIcon" />
                       </b-input-group-prepend>
                       <b-form-input
                          v-model="form.email"
                           id="icons-mail"
                           placeholder="example@.com"
                       />
                     </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                   </validation-provider>
                   </b-form-group>
                </b-col>



                    <!-- Service charge per hour -->

               <b-col cols="12" md="12" >
                   <b-form-group
                      label="Service charge per hour"
                      label-for="h-charge"
                   >
                     <b-input-group class="input-group-merge">
                       <b-input-group-prepend is-text>
                         <feather-icon icon="DollarSignIcon"/>
                       </b-input-group-prepend>
                       <b-form-input
                           v-model="form.service_charge"
                           id="icons-charge"
                           placeholder="0.00"
                       />
                     </b-input-group>
                   </b-form-group>
                </b-col>


          <!-- Form Actions -->
          <div class="d-flex mt-3 ml-1">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="updatePractice()"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                type="button"
                variant="outline-white"
                @click="close()"
            >
              Close
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {

  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,

} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import practice from '@/apis/modules/practice'
import settings from '@/apis/modules/settings'



export default {

  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewLocationSidebarActive',
    event: 'update:is-add-new-location-sidebar-active'
  },
  props: {
    isAddNewLocationSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    },
    practice:{
      type:Object,
      required:true
    }
  },
  data () {
    return {

      form:{
        name:'',
        address:'',
        address_2:'',
        city:'',
        postal_code:''
      },
      practiceList:[],
      practice_type_id: '',
      required,
      alphaNum,
      email,
      start_date: '',
      end_date: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      hide : true
    }
  },
  methods: {
    async practiceTypeList (){
      try {

        const practiceListResponse =  await settings.getPracticeType()
        this.practiceList = practiceListResponse.data.data
        this.practice_type_id = _.find(this.practiceList, {id:   this.practice.type.id})

      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    close(){
      this.$emit('toggleSideBars')
    },
    async updatePractice(){
      try {
        this.form.type_id= this.practice_type_id.id
        this.form.service_charge = parseInt(this.form.service_charge)
        const response = await practice.editPractice(this.form)
        this.showSuccessMessage('Practice Updated Successfully')
        this.$emit('toggleSideBars')
        this.$emit('resetData')

      }catch (error){
        console.error(error);

      }

    },
    toggle() {
      this.$emit('toggleSideBars')
    },
    async setDate(){
     // this.practice_type_id =  this.practice.type.id
      this.form.name =  this.practice.name
      this.form.address = this.practice.address
      this.form.address_2 = this.practice.address_2
      this.form.city = this.practice.city
      this.form.postal_code = this.practice.postal_code
     // this.form.type_id = this.practice.type.id
      this.form.phone_number = this.practice.phone_number
      this.form.email = this.practice.email
      this.form.service_charge = this.practice.service_charge


    }
  },
  beforeMount () {
    this.practiceTypeList()
    this.setDate()
  },
  async mounted () {
    await this.practiceTypeList()
    await this.setDate()

  }

}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
