<template>
  <div>
<!--    <router-view></router-view>-->
    <b-card class="mb-0" no-body>
      <div class="">
        <b-button
            class=" float-right mb-1 button-size"
            size="m"
            variant="primary"
            @click="toggleSideBars()"
        >
          <span class="align-middle" >+ Add Service</span>
        </b-button>
      </div>
      <div>
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
        <b-row>

          <b-col>
            <b-table
                :current-page="currentPage"
                :per-page="pagination.perPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="getService"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"

                hover
                ref="table"

                class="mobile_table_css"
                responsive
            >
              <!-- Name & Badge -->
              <template #cell(name)="data,index">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    {{ data.item.name }}
                  </li>
                </ul>
              </template>

              <!-- Status -->

              <template #cell(type)="data">
                <b-badge v-if="data.item.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
                  {{ data.item.type }}
                </b-badge>
                <b-badge v-else :variant="'light-info'" class="text-capitalize">
                  {{ data.item.type }}
                </b-badge>
              </template>
              <template #cell(action)="data">
                <div>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon"
                      @click="removeService(data.item.id)"
                      v-b-tooltip.hover.top="'Delete'"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>

              </template>

            </b-table>
          </b-col>
        </b-row>
        <!--                pagination-->
        <div class="mx-1 mb-2">
          <b-row>
            <b-col cols="12" v-if="noDataTable === 0" class="text-center">
              <span >No data for preview</span>
            </b-col>

            <!-- Pagination -->

            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
              <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
            </b-col>

            <!-- Pagination -->

            <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

              <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item">

                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>

                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>

              </b-pagination>

            </b-col>


          </b-row>
        </div>
        </b-overlay>
      </div>
    </b-card>


    <AddServiceSideBar
        ref="AddLocationSideBar"
        :is-add-service-sidebar-active="isAddServiceSidebarActive"
        @toggleSideBars="toggleSideBars()"
        @dataRefresh="dataRefresh()"
    />


  </div>
</template>

<script>


import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BMedia, BOverlay,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import AddServiceSideBar from '@/views/medicalpractice/sidebars/addServiceSideBar'
import settings from '@/apis/modules/settings'
import Ripple from 'vue-ripple-directive'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'

export default {
  name: 'services',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  components: {
    AddServiceSideBar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    vSelect,
    FeatherIcon,
    BOverlay
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data: function () {
    return {
      isAddServiceSidebarActive: false,
      tableLoading:false,
      noDataTable:'',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'name',

        },
        {
          key: 'type',
         name: 'TYPE'
        },
        {
          key: 'description',
          name:'Description'
        },
        {
          key: 'date_added',
          name: 'DATE ADDED',
          sortable: false
        },
        {
          key:'action'
        }
      ],
      items: []
    }
  },
  methods: {
    dataRefresh(){
      this.$refs.table.refresh()
      this.isAddServiceSidebarActive = false

    },
    toggleSideBars() {
      this.isAddServiceSidebarActive = !this.isAddServiceSidebarActive
    },
    async getService(){
      try {
        this.tableLoading = true
        const Response= await settings.getServicesInPractice(this.currentPage, 15 )
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          type: x.type,
          description: x.description?x.description :'N/A',
          date_added: x.pivot.updated_at?this.momentFormat(x.pivot.updated_at,'dddd DD-MM-YYYY'):this.momentFormat(x.updated_at,'dddd DD-MM-YYYY')
        }))
        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray
      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },
    async removeService(id){
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to delete this service?',
          text: "This action cannot be reverted.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await settings.removeServiceForPractice(id)
            this.$refs.table.refresh()
            this.showSuccessMessage('Your service has been deleted.')
            // this.$swal({
            //   icon: 'success',
            //   title: 'Deleted!',
            //   text: 'Your service has been deleted.',

            //   customClass: {
            //     confirmButton: 'btn btn-success',
            //   },
            // })
          } else if (result.dismiss === 'cancel') {
            this.$refs.table.refresh()
            this.tableLoading = false
            this.showSuccessMessage('Cancelled')
            // this.$swal({
            //   title: 'Cancelled',
            //   text: ' ',

            //   icon: 'error',
            //   customClass: {
            //     confirmButton: 'btn btn-success',
            //   },
            // })
          }
        })
        //  await settings.removeServiceForPractice(id)
        // this.showSuccessMessage('Remove service Successful')
        // this.$refs.table.refresh()

      }catch (error){
        this.convertAndNotifyError(error)
      }
    }
  },
  mounted () {

  }
}
</script>

<style scoped>

</style>
