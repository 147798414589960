<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isSendConnectionRequestSideBarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-send-connection-request-side-bar-active', val)"
      
  >
    <template #default="{ hide }">
      
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Send Connection Requests
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"   
      >
        <!-- Form -->
        <div
            class="p-2"
        >

          <label class="font-small-3 font-weight-bold pb-1">Select from the list of non connected PCN's</label>

         <!-- Search Bar -->

          <b-col  cols="12" md="12" class="pb-0">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
              v-model="filters['search']"
              placeholder="Search" />
            </b-input-group>
          </b-col>

           <b-overlay
            :show="tableLoading"
            rounded="sm"
            
          >
          <!-- Todo List -->
          <!-- <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="todo-task-list-wrapper list-group scroll-area min-vh-50 mx-1"
              style="height: 67vh;border: 1px solid #E5E5E5;border-radius: 4px;"
          > -->
         

          <b-table
              id="connection_table"
              ref="table"
              :current-page="currentPage"
              :per-page="pagination.perPage"
              :items="getNonConnectedPcns"
              :selectOnCheckboxOnly="true"
              :filter="filter"
              :filter-included-fields="filterOn"
              :fields="fields"
              class="custom_align mobile_table_css bank-tbl"
              hover
              responsive
          >

            <template #cell(selected)="data">
              <b-form-checkbox class="p-0 m-0" name="check_box" @change="addThisToSelectedList(data.item)">
                
              </b-form-checkbox>
            </template>
              
          </b-table>


            <!-- <ul class="list-group">
                <li
                    v-for="pcn in pcns"
                    :key="pcn.id"
                    class="todo-item py-1 list-group-item"
                >
                  <b-form-checkbox
                      
                  >
                      <span class="">{{ pcn.pcn_name }}</span>
                      <span class="ml-1" style="opacity: 0.5; ">{{ pcn.address}},</span>
                      <span class="ml-1" style="opacity: 0.5; ">{{ pcn.postal_code}}</span>


                  </b-form-checkbox>

                </li>
              </ul> -->
            <b-col cols="12" v-if="noDataTable === 0" class="text-center">
              <span >No data for preview</span>
            </b-col>
           
          <!-- </vue-perfect-scrollbar> -->
          </b-overlay>
          <div class="mx-1 mb-2">
          <b-row>
            
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
            <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
<!--              <span class="text-muted">Showing 1 to 10 of 187 entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
          <!-- Form Actions -->
          <div class="d-flex ml-1 mt-3">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="selected.length === 0 || hide_btn"
                type="submit"
                variant="primary"
                 @click="updateRequest()"
            >
              Send
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </validation-observer>
     
    </template>
  </b-sidebar>
</template>

<script>
import {

  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BOverlay,
  BTable,
  BPagination
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {alphaNum, email, required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import PCNApi from '@/apis/modules/pcn'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'

export default {
  
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BPagination,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isSendConnectionRequestSideBarActive',
    event: 'update:is-send-connection-request-side-bar-active'
  },
  props: {
    isSendConnectionRequestSideBarActive: {
      type: Boolean,
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data () {
    return {
      required,
      alphaNum,
      hide_btn: true,
      noDataTable:'',
      tableLoading: false,
      filters:{},
      filter: null,
      filterOn: [],
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      email,
      start_date: '',
      end_date: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      },
      fields: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'pcn_name',
          label: 'Name'
        },
        {
          key: 'address',
          label: 'ADDRESS'
        },
      ],
      pcns:[],
      items: [],
      selected: [],
      selectedCheck: true,
      selectedOption: {
        title: 'All',
        value: 1
      },

    }
  },
  methods: {
    addThisToSelectedList (item) {

      if (_.find(this.selected, {'id': item.id})) {

        _.remove(this.selected, function (e) {
          return e.id === item.id
        })
      } else {
        this.selected.push(item)
      }
      this.hide_btn = this.selected.length === 0
    },
    toggle () {
      //this.$emit('toggleSideBars')
      this.selected = []
      this.filters = {}
      this.$refs.table.refresh()
      this.isSendConnectionRequestSideBarActive = false
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
    dataRefresh () {
      this.$refs.table.refresh()
    },
    async getNonConnectedPcns(){
      try {
        this.tableLoading = true

        const Response = await PCNApi.nonConnectedPcns(this.currentPage, 15,this.filterQuery)

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          address: x.address + ', '+ x.city + ', ' + x.postal_code,
          pcn_name: x.name,
        }))
        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        
        this.tableLoading = false

        var checkboxes = document.getElementsByName('check_box')
        for (var checkbox of checkboxes) {
          checkbox.checked = false
        }

        return dataArray;

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async updateRequest(){
      try {
        this.tableLoading = true

        const data = this.selected.map((x) => (
            x.id
        ))


        await PCNApi.requestToConnect({ids: data})
        this.$emit('dataRefresh')
        this.showSuccessMessage('connection request were sent!')
        this.selected = []
        this.selected.length === 0
        this.$refs.table.refresh()
        
        this.tableLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.selected = []
        this.selected.length === 0
        this.tableLoading = false
      }
      this.$refs.table.refresh()
      this.tableLoading = false
    }
  },
  watch: {
    isSendConnectionRequestSideBarActive (val){
      if(val) this.getNonConnectedPcns()
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
