import api from '../axios';

export default {
  nonConnectedPcns: (page, paginate, filterQuery) =>
    api.get(
      `api/mp/pcn/connections/available-pcns/?${filterQuery}&page=${page}&paginate=${paginate}`
    ),
  pcnConnectionRequests: (page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/mp/pcn/connections/requests/?page=${page}&paginate=${paginate}&sort=${desc ? '' : '-'
      }${sort}&${filterQuery}`
    ),

  connectedPcns: (page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/mp/pcn/connections/?page=${page}&paginate=${paginate}&sort=${desc ? '' : '-'
      }${sort}&${filterQuery}`
    ),
  acceptConnectionRequest: (id) =>
    api.post(`api/mp/pcn/connections/requests/${id}/accept`),
  declineConnectionRequest: (id) =>
    api.post(`api/mp/pcn/connections/requests/${id}/decline`),

  requestToConnect: (payload) =>
    api.post('api/mp/pcn/connections/requests/request', payload),

  requestedToConnect: (page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/mp/pcn/connections/requests/requested/?page=${page}&paginate=${paginate}&sort=${desc ? '' : '-'
      }${sort}&${filterQuery}`
    ),
  practiceSummary: () => api.get('api/mp/practice/summary'),

  //get pcn staff gank
  getPCNSalariedStaff: (id,page, paginate, filterQuery, sort = '', desc = true) =>
    api.get(
      `api/pcn/staff-bank/staff-list?filter[is_locum]=0&sort=${desc ? '' : '-'
      }${sort}&${filterQuery}&page=${page}&paginate=${paginate}&auth_pcn_id=${id}`
    ),

  //user archie and restore
  // removeUser:(userID,pcnId) =>api.put(`api/pcn/staff-bank/staff-list/block/${userID}?auth_pcn_id=${pcnId}`),
  removeUser:(payload,pcnId) =>api.post(`api/pcn/staff-bank/staff-list/block-list?auth_pcn_id=${pcnId}`,payload),
  addAgain:(userID,pcnId) =>api.put(`api/pcn/staff-bank/staff-list/restore/${userID}?auth_pcn_id=${pcnId}`)
};
