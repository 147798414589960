<template>
  <div>
   <b-card class="mb-0" no-body>
     <div >
       <b-row>
         <b-col cols="3" >
           <b-form-group>
             <b-input-group class="input-group-merge">
               <b-input-group-prepend is-text>
                 <feather-icon icon="SearchIcon" />
               </b-input-group-prepend>
               <b-form-input
                   id="icons-search"
                   v-model="filters['search']"
                   placeholder="Search"
               />
             </b-input-group>
           </b-form-group>
         </b-col>
         <b-col cols="9">
           <b-button
               class=" float-right mb-1 button-size"
               size="m"
               variant="primary"
               @click="toggleSideBars()"
           >
             <span class="align-middle">Send Connection Request</span>
           </b-button>
         </b-col>
       </b-row>
     </div>
     <div>
       <b-row>

   <!--       <b-col cols="12" v-if="noDataTable === 0" class="text-center">
      <span class="font-weight-bolder" style="font-size: larger; color: #949494;">No data for preview</span>
        <div class="empty-state">
          <img class="img-fluid" src="@/assets/images/pages/Shifts.png" alt="">
          <p class="txt-grey mt-2">No Connection Requests</p>
        </div>
      </b-col> -->

         <b-col cols="12">
           <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
           <b-table
              ref="table"
              :current-page="currentPage"
              :per-page="pagination.perPage"
              :fields="fields"
              :items="getConnectionRequest"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="position-relative mobile_table_css"
              empty-text="No matching records found"
              hover
              responsive
              show-empty
           >
             <!-- Actions Button -->

             <template v-slot:head(name)="data">
               <div style="width: 150px">
               <span v-b-tooltip.hover.viewport="'Sort'">{{ data.label }}</span>
               </div>
             </template>

             <template v-slot:head(date_request_send)="data">
               <span v-b-tooltip.hover.viewport="'Sort'">{{ data.label }}</span>
             </template>

             <template #cell(actions)="data">
               <div class="button">
               <b-button
                   size="23"
                   variant="outline-white"
                   class="btn-icon bg-white text-primary m-1"
                   v-b-tooltip.hover.top="'Accept'"
                   @click="acceptRequest(data.item.id)"
               >
                 <feather-icon icon="CheckSquareIcon" size="18" />
               </b-button>



               <b-button
                   size="23"
                   variant="outline-white"
                   class="btn-icon bg-white text-danger m-1"
                   v-b-tooltip.hover.top="'Delete'"
                   @click="deleteRequest(data.item.id)"
               >
                 <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
               </b-button>
               </div>
             </template>
           </b-table>
           </b-overlay>
         </b-col>


         <!-- Pagination -->

<!--            <b-col cols="12" v-if="noDataTable === 0" class="text-center">-->
<!--              <span >No data for preview</span>-->
<!--            </b-col>-->
         <b-col  class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
           <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
         </b-col>
         <!-- Pagination -->

         <b-col v-if="noDataTable !== 0" class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

           <b-pagination
              v-model="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              class="mb-0 mt-1 mt-sm-0 "
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item">

             <template #prev-text>
               <feather-icon icon="ChevronLeftIcon" size="18"/>
             </template>

             <template #next-text>
               <feather-icon icon="ChevronRightIcon" size="18"/>
             </template>

           </b-pagination>

         </b-col>

         <SendConnectionRequestSideBar
          ref="SendConnectionRequestSideBar"
          :is-send-connection-request-side-bar-active="isSendConnectionRequestSideBarActive"
          @toggleSideBars="toggleSideBars()"
          @dataRefresh="dataRefresh()"
        />
       </b-row>
     </div>
   </b-card>
  </div>

</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BPopover,
  BOverlay,
  BAlert,
  BCard, BMedia, BLink, BInputGroupPrepend, BAvatarGroup,VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon'
import PCNApi from '@/apis/modules/pcn'
import Filter from '@/mixins/FilterMixin'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import SendConnectionRequestSideBar from '@/views/medicalpractice/sidebars/sendConnectionRequestSideBar.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    vSelect,
    FeatherIcon,
    SendConnectionRequestSideBar,
    VBTooltip
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {

    return {
      tableLoading: false,
      isSendConnectionRequestSideBarActive: false,
      popoverShow: false,
      noDataTable:'',
      popover2Show: false,
      pageOptions: '',
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters:{},
      filter: null,
      filterOn: [],
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label: 'NAME',
          sortable: true
        },
        {
          key: 'address',
          label: 'Address',
        },
        {
          key: 'city',
          label: 'City',
        },
        {
          key: 'postal_code',
          label: 'Postal code',
        },

        {
          key: 'telephone',
          label: 'telephone',
        },
        {
          key: 'email',
          label: 'email',
        },
        {
          key: 'date_request_send',
          label: 'date request sent',
          sortable: true
        },
        {
          key: 'actions',
          label: 'actions',
        }

      ],
      items: []
    }
  },
  methods:{
    toggleSideBars() {
      this.isSendConnectionRequestSideBarActive = !this.isSendConnectionRequestSideBarActive
    },
    dataRefresh(){
      this.$refs.table.refresh()
      this.isSendConnectionRequestSideBarActive = false
      //this.$emit('changeValue')
    },
    async getConnectionRequest() {

      try {
        this.tableLoading = true

        if (this.sortBy === '') {
          this.sortDesc = true
        }

        const Response = await PCNApi.pcnConnectionRequests(this.currentPage, 15, this.filterQuery, this.sortBy, this.sortDesc)

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          pcn_id: x.p_c_n_id,
          address: x.pcn.address,
          city: x.pcn.city,
          name: x.pcn.name,
          postal_code: x.pcn.postal_code,
          telephone: x.pcn.owner.phone_number ? x.pcn.owner.phone_number : 'N/A',
          email: x.pcn.owner.email,
          date_request_send: this.momentFormat(x.created_at, 'dddd DD-MM-YYYY')
        }))

        this.noDataTable = Response.data.data.length
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false

        return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteRequest(id){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to delete this request?',
        text: '',
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
           if (result.value) {
              try{
                this.tableLoading = true
                await PCNApi.declineConnectionRequest(id)
                this.$refs.table.refresh()
                this.tableLoading = false
                this.showSuccessMessage('Request deleted successfully!')
                this.$emit('dataRefresh')
              }catch(error){
                this.convertAndNotifyError(error)
                this.tableLoading = false
              }
           }
        })

      this.tableLoading = false

    },
    async acceptRequest(id){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to accept this request?',
        imageUrl: require('@/assets/images/icons/publish.png'),
        imageWidth: 80,
        imageHeight: 80,
        text: '',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
          if(result.value){
            try{
              this.tableLoading = true
              await PCNApi.acceptConnectionRequest(id)
              this.$refs.table.refresh()
              this.tableLoading = false
              this.showSuccessMessage('Request accepted successfully!')
              this.$emit('dataRefresh')
            }catch(error){
              this.convertAndNotifyError(error)
              this.tableLoading = false
            }
          }
        })
      this.tableLoading = false
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
  },
}
</script>
<style>
.button {
  display: flex;
  flex-direction: row;
}
</style>


<style scoped>
/*//custom align for locations table to meet the collapse alignments*/
</style>
