<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSendStaffConnectionRequestSideBarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="
      (val) =>
        $emit('update:is-send-staff-connection-request-side-bar-active', val)
    "
  >
    <template #default="{ hide }">
      <div>
        <b-card>
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-1
            "
          >
            <h4 class="mb-0">Share staff</h4>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>

          <b-col class="mt-1 mb-4">
            <p>
              Select the staff you would like to share with the PCN or other
              organisation. You can stop sharing staff by deselecting them
            </p>
          </b-col>

          <b-col cols="12" md="12" class="pb-0 mt-1">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="filters['search']" placeholder="Search" />
            </b-input-group>
          </b-col>
          <b-col class="mb-2 mt-4">
            <b-form-checkbox
              id="All"
              name="All"
              class="mt-2 mb-2"
              @change.native="SelectOrUnselectAll($event)"
            >
              <span><b>Select All Staff</b></span>
            </b-form-checkbox>
          </b-col>
          <b-overlay :show="staffLoading" rounded="sm">
            <ul class="list-group">
              <li
                v-for="role in pcnStaff"
                :key="role.id"
                class="todo-item list-group-item py-1"
              >
                <b-row>
                  <b-col md="9">
                    <span class="fw-bolder">{{ role.name }}</span>
                  </b-col>
                  <b-col md="3">
                    <b-form-checkbox
                      :value="role.name"
                      :id="role.name"
                      v-model="all"
                      @change.native="checkGroup(role, $event)"
                      class="mb-1"
                    >
                      <span
                        class="todo-title font-weight-bolder"
                        v-if="role.name"
                        >Selecet All</span
                      >
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <span
                  v-for="(person, index) in role.array"
                  class="todo-item list-group-item py-1"
                  :key="index"
                >
                  <b-form-checkbox
                    :value="person"
                    :id="role.name + '_' + person.user_id"
                    v-model="checkedStaffMembers"
                    value-field="item"
                    @change.native="getStaffs(person, role, $event)"
                  >
                    <span>{{ person.user_name }}</span>
                    <span>{{ " - " + person.practice_name }}</span>
                    <b-badge
                      v-if="person.is_locum == 1"
                      class="mr-1 mb-1"
                      style="padding: 0.8rem"
                      variant="light-info"
                    >
                      <span class="text-dark font-weight-700">L</span>
                    </b-badge>
                  </b-form-checkbox>
                </span>
              </li>
              <b-col class="mt-1" cols-12>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mb-2"
                  type="submit"
                  variant="danger"
                  @click="blockOrRestoreUser()"
                >
                  Block
                </b-button>

                <b-button
                  class="mb-2 ml-1"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="hide"
                >
                  <span class="text-secondary"> Cancel </span>
                </b-button>
              </b-col>
            </ul>
          </b-overlay>
          <!--      </vue-perfect-scrollbar>-->
        </b-card>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BOverlay,
  BTable,
  BPagination,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Filter from "@/mixins/FilterMixin";
import MomentMixin from "@/mixins/MomentMixin";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import PCNApi from "@/apis/modules/pcn";
import { ref } from "vue";

import locations from "@/apis/modules/dashboard";

export default {
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BPagination,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSendStaffConnectionRequestSideBarActive",
    event: "update:is-send-staff-connection-request-side-bar-active",
  },
  props: {
    isSendStaffConnectionRequestSideBarActive: {
      type: Boolean,
    },
    pcn_id: {
      type: Number,
    },
    roleOptions: {
      type: Array,
      required: false,
    },
    planOptions: {
      type: Array,
      required: false,
    },
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      all: [],
      pcnStaff: [],
      checkedStaffMembers: [],
      filters: {},
      filter: null,
      filterOn: [],
      staff: {
        user_id: null,
        practice_id: null,
        is_locum: false,
      },
      detailsOfMember: [],
      staffLoading: false,
      type: true,
    };
  },
  methods: {
    dataRefresh() {
      this.$refs.table.refresh();
    },
    checkGroup(option, e) {
      this.pcnStaff.forEach((value, index) => {
        if (value.name === option.name && e.target.checked === true) {
          value.array.forEach((val, i) => {
            if (
              !this.checkedStaffMembers.some(
                (o) =>
                  o.practice_id == val.practice_id && o.user_id == val.user_id
              )
            ) {
              this.checkedStaffMembers.push(val);
            }
          });
        } else if (value.name === option.name && e.target.checked === false) {
          option.array.forEach((val, i) => {
            this.checkedStaffMembers.forEach((val2, i2) => {
              if (
                val.user_id === val2.user_id &&
                val.practice_id === val2.practice_id
              ) {
                const index = this.checkedStaffMembers.indexOf(val2);
                this.checkedStaffMembers.splice(index, 1);
              }
            });
          });
        }
        this.checkSelect();
      });
    },
    getStaffs(record, role, e) {
      this.detailsOfMember.push(
        record.is_locum,
        record.user_id,
        record.practice_id
      );
      this.pcnStaff.forEach((value, index) => {
        if (value.name === role.name && e.target.checked === true) {
          if (value.array.every((r) => this.checkedStaffMembers.includes(r))) {
            console.log("Found all of value array in checkedStaffMembers");
            document.getElementById(role.name).checked = true;
          } else {
            console.log("Did not find all of value.array in selectedObjs");
          }
          this.checkSelect();
        } else if (value.name === role.name && e.target.checked === false) {
          if (!value.array.every((r) => this.checkedStaffMembers.includes(r))) {
            document.getElementById(role.name).checked = false;
            this.checkSelect();
          }
        }
      });
    },

    async checkSelect() {
      let count = 0;
      this.pcnStaff.forEach((value, index) => {
        value.array.forEach((val, i) => {
          count = count + 1;
        });
      });
      if (count === this.checkedStaffMembers.length) {
        document.getElementById("All").checked = true;
      } else {
        document.getElementById("All").checked = false;
      }
    },

    async getPcnStaffJobRoleWise() {
      try {
        this.staffLoading = true;

        const response = await locations.getPcnStaffJobRoles(
          this.filterQuery,
          this.pcn_id
        );
        const test = Object.entries(response.data.data);
        this.pcnStaff = [];
        test.forEach((x) => {
          let obj = {
            name: x[0],
            array: x[1].map((x) => ({
              user_name: x.user.first_name + " " + x.user.last_name,
              user_id: x.user.id,
              practice_name: x.practice.name,
              practice_id: x.practice.id,
              is_locum: x.is_locum,
              is_practice_archive: x.is_practice_archive,
            })),
          };
          this.pcnStaff.push(obj);
        });

        this.staffLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.staffLoading = false;
      }
    },
    SelectOrUnselectAll(e) {
      this.pcnStaff.forEach((role, index) => {
        role.array.forEach((val, i) => {
          if (e.target.checked === true) {
            if (
              !this.checkedStaffMembers.some(
                (o) =>
                  o.practice_id == val.practice_id && o.user_id == val.user_id
              )
            ) {
              this.checkedStaffMembers.push(val);
            }
            document.getElementById(role.name).checked = true;
          } else {
            this.checkedStaffMembers = [];
            document.getElementById(role.name).checked = false;
          }
        });
      });
    },
    getFormData() {
      return [this.staff];
    },
    filterQueryUpdate() {
      this.$refs.refUserListTable.refresh();
    },
    async checkedActiveUsers() {
      try {
        this.staffLoading = true;
        this.pcnStaff.map((x) => {
          x.array.map((e) => {
            if (e.is_practice_archive === 1) {
              this.checkedStaffMembers.push(e);
            }
          });
        });
        this.staffLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.staffLoading = false;
      }
    },
    async blockOrRestoreUser() {
      try {
        let payload = {
          data: this.checkedStaffMembers,
        };
        await PCNApi.removeUser(payload, this.pcn_id);
        this.showSuccessMessage("User managed successfully");
        this.$emit("dataRefresh");
      } catch (e) {
        this.convertAndNotifyError(error);
      }
    },
    async checkSelectAll() {
      let count = 0;
      await this.getPcnStaffJobRoleWise();
      await this.checkedActiveUsers();
      this.pcnStaff.forEach((value, index) => {
        value.array.forEach((val, i) => {
          count = count + 1;
        });
      });
      if (count === this.checkedStaffMembers.length) {
        document.getElementById("All").checked = true;
      } else {
        document.getElementById("All").checked = false;
      }
    },
  },
  watch: {
    async isSendStaffConnectionRequestSideBarActive(val) {
      if (val) {
        await this.checkSelectAll();
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.fw-bolder {
  font-weight: bold;
}
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
